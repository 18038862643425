.reports-table {
  thead,
  tbody {
    // display: flex;
    // flex-direction: column;
    tr {
      // display: flex;
      th {
        // width: 174px;
        // max-width: 174px !important;
        // min-width: 174px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        border-left: 2px solid rgba(224, 224, 224, 1);
        text-align: center !important;
        position: relative;
        padding: 16px 16px 25px 16px;
        &:nth-child(1) {
          text-align: left !important;
        }
        &:nth-child(2) {
          border-left: unset;
        }
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;

          //   span {
          //     width: 50%;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     &:nth-child(2) {
          //       border-left: 1px solid #e0e0e0;
          //     }
        }
      }
    }
    td {
      // width: 174px;
      // max-width: 174px !important;
      // min-width: 174px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      border-left: 2px solid rgba(224, 224, 224, 1);
      padding: 0;

      &:nth-child(2) {
        border-left: unset;
      }
      &:nth-child(1) div {
        justify-content: flex-start;
      }
      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          &:nth-child(2) {
            border-left: 1px solid #e0e0e0;
          }
        }
      }
    }
  }
}

.sumary-table {
  .MuiTable-stickyHeader {
    thead {
      width: fit-content;
      position: sticky;
      top: 0;
      z-index: 100;
      background: #fff;

      tr {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        th {
          width: 160px !important;
          max-width: 160px !important;
          min-width: 160px;

          &:nth-child(1) {
            width: 300px !important;
            max-width: 300px !important;
            min-width: 300px;
            position: sticky;
            left: 0;
            top: 0;
            z-index: 200;
            background: #fff;
            border-right: 2px solid #e0e0e0;
          }
        }
      }
    }
    tbody {
      width: fit-content;
      tr {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        td {
          width: 160px !important;
          max-width: 160px !important;
          min-width: 160px;
          &:nth-child(1) {
            width: 300px !important;
            max-width: 300px !important;
            min-width: 300px;
            position: sticky;
            left: 0;
            z-index: 99;
            background: #fff;
            border-right: 2px solid #e0e0e0;
          }
        }
      }
    }
  }
}
