.uploaded-files {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  justify-content: space-around;

  .upload-inners {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45% !important;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    &:hover {
      color: #fff;
      background-color: #ff5722;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        a {
          color: #fff !important;
        }
    }

    a {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

}
