::file-selector-button {
  display: none;
}

.m-0 {
  margin: 0 !important;
}

.uploade-img {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
}
