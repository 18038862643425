.table-merge {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 450px);
  overflow: auto;

  .table-merge-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ef5350;
    border-bottom: unset !important;
    &:last-child {
      border-bottom: 1px solid #ef5350 !important;
    }

    .merge-info-text {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 5px;
      word-break: break-all;
      width: 100%;

      border-right: 1px solid #ef5350;
    }
    svg {
      cursor: pointer;
    }
    .delete-icon {
      min-width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.add-possibility {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.possibility-drower {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  div {
    width: 100%;
  }
}
