.confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
  
    .thanks-div {
      width: 100vw;
      max-width: 665px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      cursor: pointer;
  
      .swal-modal-main {
        width: 100%;
        display: flex;
        flex-direction: column;
  
        .success-bg {
          background-color: #eaffe7 !important;
        }
  
        .swal-modal-inner {
          width: 100%;
          height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;
  
          .message-inner {
            padding-top: 10px;
            font-size: 20px;
            font-weight: 600;
            color: #000;
          }
  
          &:nth-child(1) {
            background-color: #ffe7e8;
            justify-content: center;
            padding-top: 0px;
          }
  
          .message-head {
            font-size: 24px;
            font-weight: 800;
            color: #000;
            text-align: center;
            width: 90%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
  
          .message-text {
            font-size: 18px;
            font-weight: 400;
            color: #000;
            max-width: 400px;
          }
  
          .table-detail {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            color: #5c231c;
            margin: 20px 0;
          }
        }
  
        .confirm-btn {
          width: 170px;
          height: 50px;
          background-color: #5c231c;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
  
          &:hover {
            color: #5c231c;
            background-color: #dba47480 !important;
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .confirmation {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .thanks-div {
        max-width: 272px;
        height: 394px;
        gap: 10px;
        .swal-modal-main {
          .swal-modal-inner {
            height: 142px;
  
            &:nth-child(2) {
              height: 252px;
              padding: 0;
              justify-content: center;
            }
            svg {
              width: 102px;
              height: 88px;
            }
  
            .message-head {
              font-size: 18px;
            }
  
            .table-detail {
              font-size: 12px;
              margin: 12px 0 32px 0;
              max-width: 174px;
              height: 74px;
              flex-wrap: wrap;
              text-align: center;
              background-color: #fff1e4;
              svg {
                font-size: 16px;
                width: 16px;
                height: 16px;
              }
              span {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                &:nth-child(2) {
                  display: none;
                }
              }
            }
          }
  
          .confirm-btn {
            width: 158px;
            height: 40px;
          }
        }
      }
    }
  }
  