.customer-list {
  margin-top: 10px;
  width: 250px;
  overflow: auto !important;
  height: calc(100vh - 250px);
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14),
    0px 5px 14px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;

  .customer-list-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    .group-header {
      display: flex;
      align-items: center;
      padding: 0px 16px;
      gap: 16px;
      width: 100%;
      height: 48px;
      background: #ff572238;
      font-family: "Roboto", sans-serif !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(62, 39, 35, 0.87);
    }

    .customer-list-inner {
      display: flex;
      flex-direction: column;
      width: 100%;

      .customer-name {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: rgba(62, 39, 35, 0.87);
        min-height: 48px;
        cursor: pointer;
        background-color: #fff;
        padding-left: 10px;
        flex-direction: row;
        word-break: break-word;
        &:hover {
          color: #fff;
          background-color: #ff5722;
        }
      }
    }

    .select {
      color: #fff !important;
      background-color: #ff5722 !important;
    }
  }
}
