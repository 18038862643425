.time-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5722;
  color: #ffffff;
  height: 30px;
  position: sticky;
  top: 0px;
  z-index: 101;
}
