.reservation-layout {
  .reservation-inner {
    width: 100%;
    // height: calc(100vh - 222px);
    // max-height: 704px;
    // min-height: 400px;
    overflow: auto;

    .date-time-div {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 820px;
      gap: 20px;
      padding-top: 20px !important;
      padding-left: 35px !important;

      .time-header-div {
        min-height: 50px;
        background-color: #00000014;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        padding: 0 10px;

        div {
          margin: 0;
        }
      }

      .location {
        width: 100%;
        display: flex;
        padding-top: 8px;
      }

      .date-picker-div > div > div > div > div {
        width: 100%;
        max-height: 600px;
      }

      .date-picker-div > div > div > div > div > div {
        width: 100%;
        max-height: unset;

        .PrivatePickersSlideTransition-root {
          min-height: 400px;
        }

        span {
          font-size: 24px !important;
          font-weight: 500 !important;
          width: 40px;
          height: 40px;
        }

        .MuiDayPicker-header,
        .MuiDayPicker-weekContainer {
          justify-content: space-between;
          font-size: 24px !important;
          font-weight: 500 !important;
          padding: 7px 0;

          .Mui-disabled {
            width: 40px;
            height: 40px;
          }

          button {
            font-size: 24px;
            font-weight: 500;
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .right-side {
      width: 100%;
      gap: 20px;
      max-width: 504px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px 35px;
      background-color: #fff1e4;
      position: sticky;
      top: 0;
      min-height: 440px;
      // height: calc(100vh - 220px);

      @media only screen and (max-height: 890px) {
        position: unset !important;
      }

      .right-side-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: 100%;

        .restaurant-info {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .operating-time {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .restaurant-info {
            align-items: flex-start;
          }

          .time-text-color {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        .restaurant-text {
          font-size: 16px;
          font-weight: 700;
          color: #000;

          &:nth-child(2) {
            color: #b7b6b6;
          }
        }

        .restaurant-text-inner {
          font-size: 18px;
          font-weight: 600;
          color: #000;

          &:nth-child(2) {
            color: #b7b6b6;
            font-weight: 500;
            text-align: end;
          }
        }

        .restaurant-card {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 20px;
          margin-bottom: 20px !important;

          .outlet-img {
            width: 94px;
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            img {
              width: 100%;
              height: auto;
            }
          }


          .restaurant-card-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 5px;

            .card-name {
              font-size: 21px;
              font-weight: 500;
              color: #000;
            }

            .card-pax {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 17px;
              font-weight: 500;
              color: #b7b6b6;
            }
          }
        }

        .time {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          div {
            width: 100%;
          }
        }
      }
    }
  }
}

.location {
  select {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 0.55rem;
    width: 100%;
    // max-width: 280px;
    border-radius: 4px;
  }
}

.footer-btn {
  // Button {
  //   width: 127px;
  //   height: 50px;
  //   font-size: 16px;
  //   font-weight: 500;
  //   margin: 0 !important;

  //   &:nth-child(2) {
  //     max-width: 175px !important;
  //     min-width: 127px !important;
  //     width: auto;
  //     height: 50px !important;
  //     font-weight: 600 !important;

  //   }

  // }

  .inner-btn {
    background-color: #b7b6b6;
    color: #ffffff;
  }

  // Button:hover {
  //   color: #5c231c;
  //   background-color: #dba47480 !important;
  // }
}

.date-picker-div > div > div > div > div > div > div:nth-child(1) {
  max-height: 18px;
  min-height: 18px;
  margin: 0 0 18px 0;
  padding: 0;
}

.expandedtrue {
  display: block;
}

.expandedfalse {
  display: none;
}

.stepper-two {
  display: flex;
  align-items: flex-start;
  gap: 44px;
  max-height: 762px;
  overflow: auto;
  height: calc(100vh - 218px);

  .stepper-two-left {
    width: 100%;
    max-width: 824px;
    display: flex;
    flex-direction: column;
    padding: 29px 0 0 48px;
    gap: 39px;

    .date-picker-div > div > div > div > div {
      width: 100%;
      max-height: 600px;
    }

    .date-picker-div > div > div > div > div > div {
      width: 100%;
      max-height: unset;

      .PrivatePickersSlideTransition-root {
        min-height: 400px;
      }

      span {
        font-size: 24px !important;
        font-weight: 500 !important;
        width: 40px;
        height: 40px;
      }

      .MuiDayPicker-header,
      .MuiDayPicker-weekContainer {
        justify-content: space-between;
        font-size: 24px !important;
        font-weight: 500 !important;
        padding: 7px 0;

        .Mui-disabled {
          width: 40px;
          height: 40px;
        }

        button {
          font-size: 24px;
          font-weight: 500;
          width: 40px;
          height: 40px;
        }
      }
    }

    .date-picker-div > div > div > div > div > div > div:nth-child(1) {
      max-height: 18px;
      min-height: 18px;
      margin: 0 0 18px 0;
      padding: 0;
    }
  }

  .right-side {
    width: 100%;
    gap: 20px;
    max-width: 504px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 35px;
    background-color: #fff1e4;
    // min-height: 440px;
    // max-height: 711px;
    // height: calc(100vh - 270px);

    max-height: 712px;
    // min-height: 379px;
    height: calc(100vh - 369px);
    overflow: hidden;

    .right-side-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 100%;

      // .restaurant-info {
      //   width: 100%;
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-between;
      // }

      .operating-time {
        display: flex;
        flex-direction: column;
        gap: 10px;

        // .restaurant-info {
        //   align-items: flex-start;
        // }

        .time-text-color {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .restaurant-text {
        font-size: 16px;
        font-weight: 700;
        color: #000;

        &:nth-child(2) {
          color: #b7b6b6;
        }
      }

      .restaurant-text-inner {
        font-size: 18px;
        font-weight: 600;
        color: #000;

        &:nth-child(2) {
          color: #b7b6b6;
          font-weight: 500;
          text-align: end;
        }
      }

      .restaurant-card {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px !important;

        .outlet-img {
          width: 94px;
          height: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          img {
            width: 100%;
            height: auto;
          }
        }


        .restaurant-card-inner {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 5px;

          .card-name {
            font-size: 21px;
            font-weight: 500;
            color: #000;
          }

          .card-pax {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 17px;
            font-weight: 500;
            color: #b7b6b6;
          }
        }
      }

      .time {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .time-inner {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        

        div {
          width: 100%;
        }
      }
    }
    .footer-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      Button {
        width: 127px;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        margin: 0 !important;

        &:nth-child(2) {
          max-width: 175px !important;
          min-width: 122px !important;
          width: auto;
          height: 50px !important;
          font-weight: 600 !important;
        }
      }

      .inner-btn {
        background-color: #b7b6b6;
        color: #ffffff;
      }

      Button:hover {
        color: #5c231c;
        background-color: #dba47480 !important;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .stepper-two {
    gap: 20px;
    max-height: unset;
    height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;

    .stepper-two-left {
      padding: 0;
      gap: 20px;
      width: calc(100vw - 40px) !important;
      margin: 0 auto;

      .date-picker-div > div > div > div > div {
        min-height: 270px;
        overflow: hidden;
      }

      .date-picker-div > div > div > div > div > div {
        width: calc(100vw - 40px) !important;
        max-height: unset;
        margin: 0;

        .PrivatePickersSlideTransition-root {
          min-height: 270px;
          overflow: hidden;
        }

        span {
          font-size: 14px !important;
          width: 30px;
          height: 30px;
        }

        .MuiDayPicker-header,
        .MuiDayPicker-weekContainer {
          font-size: 14px !important;
          padding: 5px 0;

          .Mui-disabled {
            width: 30px;
            height: 30px;
          }

          button {
            font-size: 14px;
            width: 30px;
            height: 30px;
          }
        }
      }

      .date-picker-div > div > div > div > div > div > div:nth-child(1) {
        max-height: 18px;
        min-height: 18px;
        margin: 0 0 18px 0;
        padding: 0;
      }
    }

    .right-side {
      width: calc(100vw - 40px);
      max-width: 464px;
      gap: 20px;
      padding: 20px;
      height: unset;
      min-height: unset;
      overflow: unset;

      .right-side-inner {
        gap: 15px;
        height: 100%;

        .operating-time {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .time-text-color {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        .restaurant-text {
          font-size: 12px;
        }

        .restaurant-text-inner {
          font-size: 14px;
        }

        .restaurant-card {
          gap: 15px;
          margin-bottom: 0px !important;
          .restaurant-card-inner {
            .card-name {
              font-size: 14px;
            }

            .card-pax {
              font-size: 12px;
              svg {
                font-size: 15px;
              }
            }
          }
        }

        .time {
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          // flex-direction: row;

          // & > div {
          //   width: 50%;
          // }
        }
      }
      .footer-btn {
        Button {
          width: 130px;
          height: 40px;
          font-size: 12px;

          &:nth-child(2) {
            max-width: 175px !important;
            min-width: 122px !important;
            height: 40px !important;
          }
        }
      }
    }
  }
}

:root {
  --size-bezel: 0.5rem;
}

.input {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
    margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
    background: pink;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: var(--color-background);
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
  }

  &__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
    color: currentColor;
    background: transparent;
    border-radius: var(--size-radius);
    height: 41px;

    &:focus,
    &:not(:placeholder-shown) {
      & + .input__label {
        transform: translate(0.25rem, -65%) scale(0.8);
        color: rgba(0, 0, 0, 0.6);
        background-color: #fff;
      }
    }
  }
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 37px;
  padding: 6px 16px 1px;
}

.right-side-selection {

  :root {
    --size-bezel: 0.5rem;
  }
  .input {
    position: relative;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
      margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
      background: pink;
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: var(--color-background);
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
      color: currentColor;
      background: transparent;
      border-radius: var(--size-radius);
      height: 41px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.25rem, -65%) scale(0.8);
          color: rgba(0, 0, 0, 0.6);
          background-color: #fff1e4;
        }
      }
    }
  }

  option {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 37px;
    padding: 6px 16px 1px;
  }
}
