.popup-input-editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;

  .editor-input-box {
    border: 1px solid #c7c3c3;
    border-radius: 5px;
    padding: 10px 10px;
    min-height: 396px;
  }
}
