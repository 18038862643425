.user-form-dailog {
  h2 {
    text-transform: uppercase;
    font-weight: bold;
  }
  p {
    font-weight: bold;
  }
  .add-btn {
    background: #ef5350;
    text-transform: uppercase;
    height: 36px;
    background: #ef5350;
    border-radius: 4px;
    padding: 0px 16px;
    gap: 10px;
    font-weight: 500;
    svg {
      height: 18px;
      width: 18px;
      font-weight: bold;
    }
    &:hover {
      background: #ef5350ed;
    }
  }
  .close-btn {
    color: #ef5350;
    background: #ffffff;
    text-transform: uppercase;
    border-color: rgba(62, 39, 35, 0.2);
    height: 36px;
    border-radius: 4px;
    padding: 0px 16px;
    gap: 10px;
    font-weight: 500;
    svg {
      height: 18px;
      width: 18px;
      font-weight: bold;
    }
    &:hover {
      background: #ffffff;
      border-color: rgba(62, 39, 35, 0.2);
    }
  }
  .switch span {
    color: #ef5350;

    .MuiSwitch-track {
      background-color: #ef535096;
    }
  }
}
.w-30 {
  width: 30% !important;
}