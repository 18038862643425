.table-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    .table-info-box {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
.footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    .footer-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
        Button {
            width: 50% !important;
        }
    }
    
    .close-btn {
        width: 100% !important;
    }
}
.drawer{
    z-index: 1202 !important;
}

