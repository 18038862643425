.videos-layout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  .videos-layout-inner {
    width: 100%;
    max-width: 1445px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    .videos {
      width: 100%;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      div:nth-child(1) {
        max-width: 1445px !important;
        height: 500px !important;
        width: 100vw !important;
      }
      .video-player {
        width: 100%;
        height: 100%;
        display: block;
        padding-top: unset !important;
        video {
          width: 100% !important;
          height: 100% !important;
          display: block;
          position: relative;
        }
      }
    }
    .videos-card-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;
    }
    .primary-btn Button {
      width: auto !important;
      height: auto !important;
      font-size: 15px !important;
      gap: 5px !important;
      padding: 0px !important;
    }
  }
}
