.loadingPanel {
  display: block;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  height: 100% !important;
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
  opacity: 0.8;
}

.blocked {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 9999;
  cursor: pointer;
}

body {
  font-family: "Roboto", sans-serif !important;
}

body.loading {
  /* overflow: hidden; */
}

body.loading .loadingPanel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-color path   {
  fill: #ff5722 !important;
}