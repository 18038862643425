.ivrs-flow {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.331915px;
  padding: 10px;
  color: #252f40;
}

.ivrs-group {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  padding: 10px;
  letter-spacing: -0.442553px;
  color: #252f40;
}
.p-10 {
  padding: 10px !important;
}
