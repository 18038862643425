
.primary-btn .filter-btn {
    width: 36px !important;
    height: 36px !important;
  }
  
  .training-videos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
  
    .training-videos-card {
      width: 33.33%;
      min-width: 300px;
      display: flex;
      padding: 10px;
  
      .videos-card-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
  
        .videos {
          width: 100%;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
  
        .card-info {
          display: flex;
          flex-direction: column;
          padding: 10px;
          gap: 10px;
        }
  
        .primary-btn Button {
          width: auto !important;
          height: auto !important;
          font-size: 15px !important;
          gap: 5px !important;
          padding: 0px !important;
        }
      }
    }
  }
  