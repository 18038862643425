.upload-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 200px;
    margin: auto;
    border: 2px dotted #ff5722;
    border-radius: 3px;
    position: relative;
    &:hover {
        background-color: #ff56223a;
    }
    .upload-btn {
        background-color: #fff !important;
        color: #ff5722;
        border-radius: 100%;
        width: 80px;
        height: 80px;
        box-shadow: 0px 3px 1px -2px #ff572269, 0px 2px 2px 0px #ff57228f, 0px 1px 5px 0px #ff5722;
        svg {
            font-size: 50px;
        }
    }
    img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
    }
}
