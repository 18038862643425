.reservation-table {
  table {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px #0000002e, 0px 1px 1px 0px #0000002e,
      0px 1px 3px 0px #0000002e;
    width: 100%;
    tr {
      th {
        font-family: "Roboto", sans-serif !important;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
        display: table-cell;
        vertical-align: inherit;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        text-align: center;
        padding: 16px;
        color: rgba(0, 0, 0, 0.87);
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #fff;
        width: 200px;
        max-width: 200px;
        min-width: 200px;
        border-left: 2px solid rgba(224, 224, 224, 1);
      }
      td {
        font-family: "Roboto", sans-serif !important;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        display: table-cell;
        vertical-align: inherit;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        text-align: center;
        padding: 16px;
        color: rgba(0, 0, 0, 0.87);
        &:nth-child(even) {
          border-left: 2px solid rgba(224, 224, 224, 1);
        }
        &:nth-child(odd) {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }
  }
}

.report-table {
  thead,
  tbody {
    tr {
      th {
        border-left: 2px solid rgba(224, 224, 224, 1);
        text-align: center !important;
        position: relative;
        padding: 16px 16px 25px 16px;
        &:nth-child(1) {
          text-align: left !important;
        }
        &:nth-child(2) {
          border-left: unset;
        }
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;

          span {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:nth-child(2) {
              border-left: 1px solid #e0e0e0;
            }
          }
        }
      }
      td {
        border-left: 2px solid rgba(224, 224, 224, 1);
        padding: 0;

        &:nth-child(2) {
          border-left: unset;
        }
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            &:nth-child(2) {
              border-left: 1px solid #e0e0e0;
            }
          }
        }
      }
    }
  }
}

.event-table {
  .MuiTable-stickyHeader {
    thead {
      width: fit-content;
      position: sticky;
      top: 0;
      z-index: 100;
      background: #fff;

      tr {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        th {
          width: 160px !important;
          max-width: 160px !important;
          min-width: 160px;

          &:nth-child(1) {
            width: 300px !important;
            max-width: 300px !important;
            min-width: 300px;
            position: sticky;
            left: 0;
            top: 0;
            z-index: 200;
            background: #fff;
            border-right: 2px solid #e0e0e0;
          }
        }
      }
    }
    tbody {
      width: fit-content;
      tr {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        td {
          width: 160px !important;
          max-width: 160px !important;
          min-width: 160px;
          &:nth-child(1) {
            width: 300px !important;
            max-width: 300px !important;
            min-width: 300px;
            position: sticky;
            left: 0;
            z-index: 99;
            background: #fff;
            border-right: 2px solid #e0e0e0;
          }
        }
      }
    }
  }
}
