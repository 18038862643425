.stepper-six .conditions-form {
  max-width: 800px !important;
}

// .stepper-six .conditions-form .input-box .input-box-inner {
//   padding: 10px 0px !important;
// }

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.stepper-six .conditions-form .input-box .input-box-inner {
  padding: 5px 20px;
}

.mt-20 {
  margin-top: 20px;
}
