.swal-modal {
  width: 665px;
  height: 500px;
  position: relative;

  .swal-content {
    padding: 0 !important;
    margin: 0 !important;

    .swal-modal-main {
      width: 100%;
      display: flex;
      flex-direction: column;

      .success-bg {
        background-color: #eaffe7 !important;
      }

      .swal-modal-inner {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;

        &:nth-child(1) {
          background-color: #ffe7e8;
          justify-content: center;
          padding-top: 0px;
        }

        .message-head {
          font-size: 24px;
          font-weight: 800;
          color: #000;
          text-align: center;
          width: 90%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden
        }

        .message-text {
          font-size: 18px;
          font-weight: 400;
          color: #000;
          max-width: 400px;
        }

        .table-detail {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 600;
          color: #5c231c;
          margin: 25px 0;
        }
      }
    }
  }

  .swal-footer {
    position: absolute;
    right: 0;
    bottom: 45px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    .swal-button-container {
      margin: 0;

      .swal-button {
        width: 170px;
        height: 50px;
        background-color: #5c231c;
        color: #fff;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          color: #5c231c;
          background-color: #dba47480 !important;
        }
      }
    }
  }
}