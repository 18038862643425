.integration-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 0px 2px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 15px;
  .integration_text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
  }

  .integration_main {
    width: 100%;
    display: block;
    font-size: 15px;
    .integration_inner {
      display: grid;
      line-height: 25px;
      margin-bottom: 10px;
    }
    .integration_btn {
      padding-top: 10px;
    }
  }
}
.select {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-weight: bold;
  }
}
.switch_integration {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.inte_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.btn-integration {
  display: contents;
  .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded,
  .css-1fx8m19 {
    transform: unset !important;
  }
  .accordion-details {
    margin-top: -70px;
    background-color: #fff;
    z-index: 66;
    position: sticky;
  }
}
.integration-box-inner {
  width: 100%;
  display: flex;
  gap: 20px;

  p {
    font-weight: bold;
  }
}
