.user-form-dailog {
    h2 {
        text-transform: uppercase;
        font-weight: bold;
    }
    .add-btn {
        background: #EF5350;
        text-transform: uppercase;
        padding: 0px 16px;
        gap: 10px;
        width: 114px !important;
        border-color: rgba(62, 39, 35, 0.2);
        height: 36px;
        border-radius: 4px;
        padding: 0px 16px;
        gap: 10px;
        font-weight: 500;

        svg {
            height: 18px;
            width: 18px;
            font-weight: bold;
        }
        &:hover {
            background: #ef5350ed;
        }
    }
    .close-btn {
        color: #EF5350;
        background: #FFFFFF;
        text-transform: uppercase;
        width: 114px;
        border-color: rgba(62, 39, 35, 0.2);
        &:hover {
            color: #EF5350;
            background: #FFFFFF;
            border-color: rgba(62, 39, 35, 0.7);
        }
    }
    .switch span {
        color: #EF5350;
        .MuiSwitch-track {
            background-color: #ef535096;
        }
    }
}

.warning-div {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
        color: #EF5350;
    }
}