.reservation-layout {
  .reservation-inner {
    width: 100%;
    // height: calc(100vh - 222px);
    // max-height: 704px;
    // min-height: 400px;
    overflow: auto;
  }
}

// .footer-btn {
//   Button {
//     width: 127px;
//     height: 50px;
//     font-size: 16px;
//     font-weight: 500;

//     &:nth-child(2) {
//       max-width: 175px !important;
//       min-width: 127px !important;
//       width: auto;
//       height: 50px !important;
//       font-weight: 600 !important;

//     }

//   }

//   Button:hover {
//     color: #5C231C;
//     background-color: #DBA47480 !important;
//   }
// }

.stepper-three {
  display: flex;
  align-items: flex-start;
  gap: 44px;
  max-height: 762px;
  overflow: auto;
  height: calc(100vh - 218px);

  .stepper-three-left {
    width: 100%;
    max-width: 824px;
    display: flex;
    flex-direction: column;
    padding: 29px 0 0 48px;
    gap: 39px;

    .time-header-div {
      min-height: 50px;
      background-color: #00000014;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding: 0 10px;

      div {
        margin: 0;
      }
    }
    .times {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 19px;
      height: 125px;
      overflow: auto;

      .time:hover {
        background-color: #ff57221c;
      }

      .time {
        width: 16.66%;
        max-width: 94px;
        min-width: 94px;
        height: 47px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        color: #b7b6b6;
        border: 2px solid #b7b6b6;

        &:hover {
          color: #5c231c !important;
          background-color: #dba47480 !important;
          border-color: #5c231c !important;
        }

        .time-inner {
          width: 100%;
          height: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 500;
          position: relative;

          .discount-chip {
            position: absolute;
            font-family: "Roboto", sans-serif !important;
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 6px;
            text-align: right;
            letter-spacing: -0.387234px;
            color: #ff5722;
            top: 2px;
            right: 2px;
            display: flex;
            align-items: center;
            gap: 2px;

            svg {
              font-size: 10px;
              border: 1px solid #ff5722;
              border-radius: 100%;
              padding: 1px;
            }
          }
        }
      }
    }

    .times-other {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 19px;
      height: 125px;
      overflow: auto;

      .time:hover {
        background-color: #ff57221c;
      }

      .time {
        width: 20%;
        max-width: 136px;
        min-width: 136px;
        height: 47px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        color: #b7b6b6;
        border: 2px solid #b7b6b6;

        &:hover {
          color: #5c231c !important;
          background-color: #dba47480 !important;
          border-color: #5c231c !important;
        }

        .time-inner {
          width: 100%;
          height: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 500;
          position: relative;

          .discount-chip {
            position: absolute;
            font-family: "Roboto", sans-serif !important;
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 6px;
            text-align: right;
            letter-spacing: -0.387234px;
            color: #ff5722;
            top: 2px;
            right: 2px;
            display: flex;
            align-items: center;
            gap: 2px;

            svg {
              font-size: 10px;
              border: 1px solid #ff5722;
              border-radius: 100%;
              padding: 1px;
            }
          }
        }
      }
    }

    .selected {
      color: #5c231c !important;
      background-color: #dba47480 !important;
      border-color: #5c231c !important;
    }

    .time-text-color {
      color: #b7b6b6 !important;
    }
  }

  .right-side {
    width: 100%;
    gap: 20px;
    max-width: 504px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 35px;
    background-color: #fff1e4;
    // min-height: 440px;
    // max-height: 711px;
    // height: calc(100vh - 270px);

    max-height: 712px;
    // min-height: 379px;
    height: calc(100vh - 369px);
    overflow: hidden;

    .right-side-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 100%;

      .restaurant-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .operating-time {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .restaurant-info {
          align-items: flex-start;
        }

        .time-text-color {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .restaurant-text {
        font-size: 16px;
      }

      .restaurant-text-inner {
        font-size: 18px;
        font-weight: 600;
        color: #000;

        &:nth-child(2) {
          color: #b7b6b6;
          font-weight: 500;
          text-align: end;
        }
      }

      .restaurant-card {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px !important;

        .outlet-img {
          width: 94px;
          height: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          img {
            width: 100%;
            height: auto;
          }
        }


        .restaurant-card-inner {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 5px;

          .card-name {
            font-size: 21px;
            font-weight: 500;
            color: #000;
          }

          .card-pax {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 17px;
            font-weight: 500;
            color: #b7b6b6;
          }
        }
      }

      .time {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        div {
          width: 100%;
        }
      }
    }
    .footer-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      Button {
        width: 127px;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        margin: 0 !important;
      }

      .inner-btn {
        background-color: #b7b6b6;
        color: #ffffff;
      }

      Button:hover {
        color: #5c231c;
        background-color: #dba47480 !important;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .stepper-three {
    gap: 20px;
    max-height: unset;
    height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;

    .stepper-three-left {
      padding: 0;
      gap: 20px;
      width: calc(100vw - 40px) !important;
      margin: 0 auto;

      .time-header-div {
        min-height: 40px;
        font-size: 12px;
      }
      .times {
        gap: 19px;
        height: 114px;
        .time {
          width: 28.33%;
          max-width: unset;
          min-width: 96px;
          height: 43px;

          @media only screen and (max-width: 410px) {
            width: 44%;
            max-width: unset;
          }

          .time-inner {
            height: 43px;
          }
        }
      }

      .times-other {
        gap: 19px;
        height: 114px;

        .time {
          width: 44%;
          max-width: unset;
          min-width: unset;
          height: 43px;

          .time-inner {
            height: 43px;
          }
        }
      }
    }

    .right-side {
      width: calc(100vw - 40px);
      max-width: 464px;
      gap: 20px;
      padding: 20px;
      height: unset;
      min-height: unset;
      overflow: unset;

      .right-side-inner {
        gap: 15px;
        height: 100%;

        .operating-time {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .restaurant-info {
            align-items: flex-start;
          }

          .time-text-color {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        .restaurant-text {
          font-size: 12px;
        }

        .restaurant-text-inner {
          font-size: 12px;
        }

        .restaurant-card {
          gap: 15px;

          .restaurant-card-inner {
            gap: 5px;

            .card-name {
              font-size: 14px;
            }

            .card-pax {
              font-size: 12px;
              svg {
                font-size: 15px;
              }
            }
          }
        }
      }
      .footer-btn {
        Button {
          width: 130px;
          height: 40px;
          font-size: 12px;
        }
      }
    }
  }
}

:root {
  --size-bezel: 0.5rem;
}

.input {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
    margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
    background: pink;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: var(--color-background);
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
  }

  &__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
    color: currentColor;
    background: transparent;
    border-radius: var(--size-radius);
    height: 41px;

    &:focus,
    &:not(:placeholder-shown) {
      & + .input__label {
        transform: translate(0.25rem, -65%) scale(0.8);
        color: rgba(0, 0, 0, 0.6);
        background-color: #fff;
      }
    }
  }
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 37px;
  padding: 6px 16px 1px;
}
