.customer-management {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  position: relative;
  .management-left {
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 65px;
  }
  .management-right {
    width: 100%;
    display: flex;
    flex-grow: 1;
  }
}

.card-shadow {
  height: unset !important;
}
