.notice-board {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .notice-board-header {
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #3e2723;
    margin: 23px 0 10px;
    align-items: center;
    display: flex;
    gap: 5px;

    svg {
      color: #ef5350;
      font-size: 40px;
      cursor: pointer;
    }
  }

  .notice-board-cardlist {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;

    .add-card {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 16px;
      min-width: 278px;
      max-width: 345px;
      width: 100%;
      height: 200px;
      background: #ffdf80;
      border: 1px solid #ffc61a;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px;

      svg {
        color: #8d6e63;
        font-size: 60px;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      min-width: 476px;
      max-width: 600px;
      width: 100%;
      height: 400px;
      background: #83f1ad;
      border: 1px solid #83f1ad;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      position: relative;
      &:hover .card-btn {
        display: flex;
      }
    }

    .danger {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      min-width: 476px;
      max-width: 600px;
      width: 100%;
      height: 400px;
      background: #e68282;
      border: 1px solid #e68282;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      position: relative;
      &:hover .card-btn {
        display: flex;
      }
    }

    .liberal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      min-width: 476px;
      max-width: 600px;
      width: 100%;
      height: 400px;
      background: #ffdf80;
      border: 1px solid #ffdf80;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      position: relative;
      &:hover .card-btn {
        display: flex;
      }
    }

    .card-text {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #252f40;
      word-break: break-word;
      white-space: normal;
      padding: 28px 16px 16px 16px;
      overflow: auto;
      height: 400px;
      width: 100%;
    }
  }
}

.pagination-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  button {
    width: 34px;
    height: 34px;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .MuiPaginationItem-page.Mui-selected {
    width: 34px;
    height: 34px;
    background: #ff5722;
    border-radius: 4px;
  }
}
.card-btn {
  width: 100%;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  gap: 10px;
  padding: 5px 10px;

  svg {
    cursor: pointer;
    color: #000;
    border: 2px solid #000;
    border-radius: 100%;
    font-size: 30px;
    padding: 3px;
  }
}
