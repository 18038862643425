.images-hello::ng-deep {
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

a {
  cursor: pointer;
}

.pointers {
  cursor: pointer;
}

.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8fcff;
  width: 100%;
  height: 100vh;
}

.page-not-found img {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 700px;
}

.w_100 {
  width: 100% !important;
}

.outlet-info {
  margin-left: 12px;
}

.product-image {
  width: 60px;
  height: 60px;
  display: block;
}

.danger {
  color: #ef5350;
}

.primary-btn {
  display: flex;
  align-items: center;
  gap: 10px;

  Button {
    width: 115px !important;
    height: 36px !important;
    font-size: 15px !important;
    gap: 5px !important;
  }

  .filter-btn {
    width: 0px !important;
  }
}

.primary-btn .large {
  width: 170px !important;
}

.primary-btn Button svg {
  font-size: 20px !important;
}

.primary-btn Button a {
  width: 115px !important;
  height: 36px !important;
  font-size: 15px !important;
  gap: 5px !important;
  display: flex;
  align-items: center;
  color: #ff5b33;
  text-decoration: unset;
}

.primary-btn Button a svg {
  font-size: 20px !important;
}

body {
  font-family: "Roboto", sans-serif !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-33 {
  width: 33.33% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.gap-10 {
  display: flex;
  gap: 10px;
}

.mt_10 {
  margin-top: 10px;
}

.commands-icons {
  align-items: center;
  gap: 13px;

  svg {
    font-size: 18px;
    color: #3e27238a;
    margin: 0 5px;
    cursor: pointer;
  }
}

.popup-layout {
  .popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    width: 100%;
    padding: 20px 20px 0;

    .switch-button {
      display: flex;
      align-items: center;
    }

    h2 {
      padding: 0 10px;
      font-size: 20px;
      font-family: "Roboto", sans-serif !important;
      font-weight: 700;
      color: #000;
    }

    h3 {
      font-size: 17px;
      position: absolute;
    }

    p {
      font-weight: bold;
      text-transform: capitalize;
    }

    .view {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 3px;

      h2 {
        font-size: 20px;
      }
    }
  }

  .popup-body {
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    max-height: calc(100vh - 300px);

    .popup-input-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px;

      svg {
        color: #ff5b33;
      }

      div {
        width: 100%;
        margin-bottom: 0;

        .MuiInputAdornment-positionEnd {
          width: auto;
        }
      }

      p {
        font-weight: bold;
      }
    }

    .popup-input-boxs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px;

      svg {
        color: #ff5b33;
      }

      p {
        font-weight: bold;
      }
      .country-list {
        width: 200px;
        height: 160px;
      }
      input {
        width: 100%;
        height: 40px;
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.23);
        z-index: 10;

        &:hover {
          border-color: #000;
        }

        &:active {
          border-color: #ff5722;
        }

        &:focus {
          // border-color: #ff5722;
          border: 2px solid #ff5722;
        }
      }

      .react-tel-input .selected-flag,
      .react-tel-input .flag-dropdown {
        background-color: transparent;
      }
    }

    .react-tel-input .flag-dropdown {
      top: 2px;
      bottom: 2px;
      left: 2px;
      z-index: 11;
      border-right: 1px solid #cacaca !important;
      border: unset;
    }

    .info {
      p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(62, 39, 35, 0.54);
      }
    }
  }

  .popup-body1 {
    display: flex;
    padding: 0px;
    flex-wrap: wrap;

    .popup-input-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px;

      div {
        width: 100%;
        margin-bottom: 0;

        .MuiInputAdornment-positionEnd {
          width: auto;
        }
      }

      p {
        font-weight: bold;
      }
    }

    .info {
      p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(62, 39, 35, 0.54);
      }
    }
  }

  .primary-btn {
    padding: 0 20px 20px 0;
  }
}

.justify-end {
  justify-content: flex-end !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.conditions {
  width: 100%;
  display: flex;
  flex-direction: column;

  .conditions-text {
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.331915px;
    color: #000000;
    display: flex;
    align-items: baseline;

    svg {
      font-size: 8px;
      color: #000000;
      margin-right: 10px;
    }
  }
}

.listing-view {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center !important;
}

.basicinformation {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
  height: calc(100vh - 327px);

  .basicinformation-left {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
  }

  .basicinformation-right {
    width: 30%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    .switch-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
      border-radius: 5px;
      background-color: #ef535026;

      .switching {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: bold;
        }
      }
    }

    .upload-img {
      padding: 10px;
      border-radius: 5px;
      background-color: #ef535026;

      p {
        font-weight: bold;
      }

      .upload-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 200px;
        margin: auto;
        border: 2px dotted #ff5722;
        border-radius: 3px;
        position: relative;
        background-color: #fff;

        &:hover {
          background-color: #ff56223a;
        }

        .upload-btn {
          background-color: #fff !important;
          color: #ff5722;
          border-radius: 100%;
          width: 80px;
          height: 80px;
          box-shadow: 0px 3px 1px -2px #ff572269, 0px 2px 2px 0px #ff57228f,
            0px 1px 5px 0px #ff5722;

          svg {
            font-size: 50px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
        }
      }
    }
  }
}

.p-10 {
  padding: 10px;

  p {
    font-weight: bold;
  }
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .btn {
    margin: 5px 0;
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .header-inner-text {
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #252f40;
    cursor: pointer;

    svg {
      font-size: 18px;
    }
  }

  .header-inner-text:nth-child(2) {
    color: #67748e;
  }

  .header-inner-text1 {
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #252f40;

    svg {
      font-size: 28px;
      color: #f8bd4b;
    }
  }

  .header-inner-text2 {
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #67748e;
  }

  .header-inner-text3 {
    font-family: "Roboto", sans-serif !important;
    font-size: 30px;
    font-weight: 500;
    color: #f8bd4b;
  }

  .header-inner-sub {
    display: flex;
    flex-direction: column;

    .header-inner-text4 {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      color: #252f40;
    }
  }

  .basicinformation {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .basicinformation-left {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
    }

    .basicinformation-right {
      width: 30%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .switch-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: #af484626;
        padding: 10px;
        border-radius: 5px;

        .switching {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-weight: bold;
          }
        }
      }

      .upload-img {
        background-color: #af484626;
        padding: 10px;
        border-radius: 5px;

        p {
          font-weight: bold;
        }

        .upload-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 80%;
          height: 200px;
          margin: auto;
          border: 2px dotted #ff5722;
          border-radius: 3px;
          position: relative;
          background-color: #fff;

          &:hover {
            background-color: #ff56223a;
          }

          .upload-btn {
            background-color: #fff !important;
            color: #ff5722;
            border-radius: 100%;
            width: 80px;
            height: 80px;
            box-shadow: 0px 3px 1px -2px #ff572269, 0px 2px 2px 0px #ff57228f,
              0px 1px 5px 0px #ff5722;

            svg {
              font-size: 50px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
          }
        }
      }
    }
  }

  .p-10 {
    padding: 10px;

    p {
      font-weight: bold;
    }
  }
}

.date-picker {
  display: flex;
  gap: 10px;

  // margin-bottom: 10px;
  input {
    padding: 8.5px 14px;
  }
}

.date-picker1 {
  display: flex;
  flex-direction: column;

  input {
    padding: 8.5px 14px;
  }
}

.github-picker div {
  top: 0 !important;
  border-color: transparent !important;
}

.text_align {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.text-editor {
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.w_800 .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 800px !important;
}

.mobile {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  gap: 5px;

  .mobile-select {
    max-width: 100px;
    display: flex;
    flex-wrap: wrap;
    max-height: 41px;
  }
  .mobile-number {
    max-width: 160px;
    display: flex;
    flex-wrap: wrap;
  }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 350px !important;
  height: auto;
}

.active {
  background-color: #ff57221c !important;
  cursor: pointer;
}

.Mui-error {
  text-transform: capitalize !important;
}
