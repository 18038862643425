.repeat-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  Button {
    width: auto;
    display: flex;
    flex-grow: 1;
  }
  .active-btn {
    background-color: #ff572226;
  }
}
