.Btn {
  padding: 0;
  margin-top: 15px;
  width: 100px;
  height: 36px;
}

.forgotpassword-layout {
  max-width: 1920px;
  max-height: 1080px;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .logo {
    display: block;
    width: 200px;
    height: 74px;
    position: fixed;
    top: 63px;
    left: 77px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .forgotpassword-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 520px;
    padding: 10px;
    margin: auto;

    .forgotpassword-header-text {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 52px;
      line-height: 62px;
      letter-spacing: -2px;
      color: #252f40;
      margin: 0;
    }

    .forgotpassword-header-text1 {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #67748e;
      margin-bottom: 20px;
    }

    .login-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 35px;

      .login-input-label {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #252f40;
      }

      .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        max-width: 520px;
        width: 100vw;
        height: 60px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 4px;

        div {
          width: 100%;
          height: 100%;
        }
      }

      .error_text {
        font-family: "Roboto", sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #f03038;
      }
    }

    .login-btn {
      font-family: "Roboto", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.2px;
      color: #ffffff;
      justify-content: center;
      padding: 16px;
      width: 160px;
      height: 55px;
      background: #9747ff;
      border-radius: 9px;
      margin-top: 35px;
    }

    .option {
      display: flex;
      align-items: center;
      gap: 33px;
      width: 445px;
      height: 100px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;

      svg {
        font-size: 85px;
      }

      .option-texts {
        display: flex;
        flex-direction: column;

        .text {
          font-family: "Roboto", sans-serif !important;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #252f40;
        }
      }
    }

    .mt_10 {
      margin-top: 10px;
    }
  }
}
