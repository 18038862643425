.confirmation-card {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  gap: 20px;
  margin-top: 100px;
}
.invoice-text{
  font-size: 16px;
  font-weight: 500;
}