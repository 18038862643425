.reservation-layout {
  .hotels {
    display: flex;
    gap: 30px;

    .hotel-card:hover {
      cursor: pointer;
      background-color: #dba47480 !important;
    }
  }
}

.active {
  background-color: #dba47480 !important;
  cursor: pointer;
}

.reservation {
  display: flex;
  align-items: center;
  gap: 5px;
}

.all-cards {
  padding: 0 !important;
  overflow: auto;
  height: calc(100vh - 352px);
  max-height: 580px;
  // min-height: 400px;

  .all-cards-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    padding: 0 35px;

    .cards {
      width: 390px;
      height: 450px;
      box-shadow: unset;

      .MuiCardMedia-img {
        height: 312px;
        display: block;
        border-radius: 10px;
        object-fit: unset;
      }

      .card-header-text {
        display: flex;
        flex-grow: 1;
        font-size: 21px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }
    }

    .card-footer {
      display: flex;
      align-items: center;
      padding: 0 !important;

      svg {
        font-size: 22px;
      }

      span {
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }

  // .footer-btn {
  //   Button {
  //     width: 127px;
  //     height: 50px;
  //     font-size: 16px;
  //     font-weight: 500;

  //     &:nth-child(2) {
  //       max-width: 175px !important;
  //       min-width: 127px !important;
  //       width: auto;
  //       height: 50px !important;
  //       font-weight: 600 !important;

  //     }

  //   }

  //   Button:hover {
  //     color: #5c231c;
  //     background-color: #dba47480 !important;
  //   }
  // }
}

.tabs > div > div > div {
  justify-content: center;
  gap: 22px;

  button {
    width: 180px;
    height: 60px;
    border: 1px solid #5c231c;
    border-radius: 8px;
    color: #5c231c;
    font-size: 16px;
    font-weight: 500;
  }

  .Mui-selected {
    color: #5c231c !important;
    font-weight: 600 !important;
    background-color: #edd2ba !important;
  }
}

.tabs {
  position: sticky;
  top: 0;
  z-index: 200;
  background-color: #fff;
  padding-bottom: 50px;
}

.tabs > div > div > span {
  display: none;
}

// .Mui-selected {
//   color: #fff !important;
//   font-weight: 600 !important;
//   background-color: #5c231c !important;
// }

Mui-selected {
  color: #5c231c !important;
  font-weight: 600 !important;
  background-color: #dba47480 !important;
}

.event-description {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;

  svg {
    font-size: 14px;
  }
}

.header1 {
  display: none;
}

.stepper-one {
  width: 100%;
  max-width: 1313px;
  // height: 449px;
  display: flex;
  flex-direction: column;
  padding: 26px 0;
  margin: auto;

  .stepper-one-header {
    background-color: #fff;
    padding-bottom: 50px;

    & > div > div > div {
      justify-content: center;
      gap: 22px;

      button {
        width: 168px;
        height: 60px;
        border: 1px solid #5c231c;
        border-radius: 8px;
        color: #5c231c;
        font-size: 14px;
        font-weight: 500;
      }

      .Mui-selected {
        color: #5c231c !important;
        font-weight: 600 !important;
        background-color: #edd2ba !important;
      }
    }

    & > div > div > span {
      display: none;
    }
  }

  .restaurant-list {
    padding: 0 !important;
    overflow: auto;

    .restaurant-list-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: auto;
      height: calc(100vh - 476px);
      max-height: 599px;
      gap: 40px;

      .cards {
        width: 33.33%;
        max-width: 350px;
        max-height: 320px;
        background-color: #fff;
        // margin-bottom: 40px;
        box-shadow: unset;

        .card-inner {
          // background-color: #dfc0bc;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // padding: 0 20px;
          gap: 12px;
          height: 100%;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
         
          .card-img {
            width: 100%;
            max-width: 350px;
            height: 100%;
            max-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 10px;
            object-fit: unset;
           
            img {
              width: auto;
              height: 200px;
              display: block;
            }

          }

          

          .card-info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;

            .restaurant-name {
              font-size: 21px;
              font-weight: 600;
              line-height: 29px;
            }

            .pay-info {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              min-height: 24px;
              height: 55px;
              gap: 5px;

              button:nth-child(1) {
                width: 100%;
                .text {
                  width: 90%;
                  display: block;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  text-align: left;
                }
              }

              button {
                padding: 0;
                width: 49%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              svg {
                font-size: 24px;
              }
              .text {
                font-size: 17px;
                font-weight: 500;
                line-height: 21px;
              }
            }

            .reservation-btn {
              width: 100%;
              height: 51px;
              border-radius: 5px;
              font-size: 16px;
              font-weight: 500;
              line-height: 29px;
              margin-bottom: 10px;
              &:hover {
                background-color: #dba47480 !important;
              }
            }
          }
        }
      }
    }
  }
}

// .stepper-header-text {
// font-size: 18px;
// font-weight: 600;
// line-height: 22px;
// margin: 0 0 18px;
// padding: 0 20px;
// display: none;

// }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  // .stepper-header-text {
  //   display: flex;

  //   }
  .stepper-one {
    padding: 0;
    margin: 0 auto;

    .stepper-one-header {
      padding: 0 20px 40px;

      & > div > div > div {
        gap: 20px;
        justify-content: space-between;

        button {
          width: 130px;
          height: 40px;
          font-size: 12px;
        }
      }
    }

    .restaurant-list {
      .restaurant-list-inner {
        padding: 0 10px;
        height: calc(100vh - 254px);
        max-height: unset;

        .cards {
          width: 50%;
          max-height: 218px;
          margin-bottom: 18px;

          .card-inner {
            padding: 0 10px;
            gap: 0;

            // .MuiCardMedia-img {
            //   width: 100%;
            //   height: 120px;
            // }

            .card-info {
              gap: 0;

              .restaurant-name {
                font-size: 14px;
                @media only screen and (max-width: 380px) {
                  font-size: 12px;
                }
              }

              .pay-info {
                min-height: 15px;

                svg {
                  font-size: 15px;
                }
                .text {
                  font-size: 12px;
                  line-height: 15px;
                  padding: 0 5px;
                  @media only screen and (max-width: 380px) {
                    font-size: 10px;
                  }
                }
              }

              .reservation-btn {
                height: 40px;
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 10px;
                @media only screen and (max-width: 380px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
