.stepper-five {
  width: 100%;
  max-width: 1313px;
  display: flex;
  flex-direction: column;
  /* padding: 26px 0; */
  margin: 26px auto 0;
  height: calc(100vh - 373px);
  overflow: auto;

  .condition-info {
    background-color: #fff1e4;
    max-width: 1320px;
    padding: 15px;
    // margin: 20px auto 0;
    // width: 100%;
    .conditions-info-text {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
    }
    .conditions {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .conditions-text-inner {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          font-size: 14px;
        }
      }
    }
  }

  .conditions-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 1000px;
    margin: auto;

    .w-16 {
      width: 16.66% !important;
    }

    .input-box {
      display: flex;
      width: 50%;

      .input-box-inner {
        width: 100%;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        // width: 50% !important;
        & > div:nth-child(1) {
          width: 100%;
        }
        div input {
          width: 100%;
        }
        label {
          display: flex;
          align-items: flex-start;
        }
      }
    }

    .footer-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      Button {
        width: 127px;
        height: 50px;
        font-size: 16px;
        font-weight: 500;

        &:nth-child(2) {
          max-width: 175px !important;
          min-width: 127px !important;
          width: auto;
          height: 50px !important;
          font-weight: 600 !important;
        }
      }

      Button:hover {
        color: #5c231c;
        background-color: #dba47480 !important;
      }
    }
  }
}
.stepper-header-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 0 0px;
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .stepper-header-text {
    display: flex;
  }
  .stepper-five {
    height: calc(100vh - 150px);

    .condition-info {
      padding: 20px;
      .conditions-info-text {
        font-size: 12px;
      }
      .conditions {
        .conditions-text {
          font-size: 12px;

          svg {
            font-size: 12px;
          }
        }
      }
    }

    .conditions-form {
      .input-box {
        width: 100% !important;

        .input-box-inner label span {
          font-size: 12px !important;
        }
      }
    }
    .footer-btn {
      Button {
        width: 130px;
        height: 40px;
        font-size: 12px !important;

        &:nth-child(2) {
          max-width: 130px !important;
          min-width: 130px !important;
          height: 40px !important;
        }
      }
    }
  }
}

:root {
  --size-bezel: 0.5rem;
}

.input {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
    margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
    background: pink;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: var(--color-background);
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
  }

  &__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
    color: currentColor;
    background: transparent;
    border-radius: var(--size-radius);
    height: 41px;

    &:focus,
    &:not(:placeholder-shown) {
      & + .input__label {
        transform: translate(0.25rem, -65%) scale(0.8);
        color: rgba(0, 0, 0, 0.6);
        background-color: #fff;
      }
    }
  }
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 37px;
  padding: 6px 16px 1px;
}
